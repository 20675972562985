import React from "react"
import { Router } from "@reach/router"

import Article from "../../templates/Article"

const App = () => {
  return (
    <Router basepath="/aktualnosci">
      <Article path="/:id" />
    </Router>
  )
}

export default App
