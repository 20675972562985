import React, { useMemo } from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import { useQuery } from "urql"
import ReactFullscreenSlideshow from "react-fullscreen-slideshow"
import { Helmet } from "react-helmet"

import BannerSection from "../sections/BannerSection"
import DownloadCard from "../components/DownloadCard"
import Container from "../components/Container"
import Title from "../components/Title"

const Article = props => {
  const [{ data }] = useQuery({
    query: `
    query Assets($id: ID = "${props.id}") {
      post(where: {id: $id}) {
        banner {
          url
        }
        id
        title
        content
        assets (first: 100){
          url
          mimeType
          fileName
        }
      }
    }
    
  `,
  })

  const post = useMemo(() => data?.post, [data])

  const assets = useMemo(() => {
    const images = []
    const files = []

    data?.post.assets?.map(file => {
      switch (file.mimeType) {
        case "image/avif":
        case "image/bmp":
        case "image/vnd.microsoft.icon":
        case "image/gif":
        case "image/jpeg":
        case "image/png":
        case "image/webp":
          return images.push({ title: file.fileName, image: file.url })
        default:
          return files.push(file)
      }
    })

    return { images, files }
  }, [data])

  if (!post) return <></>

  return (
    <>
      <Helmet title={post.title} />
      <BannerSection
        title={post?.title}
        image={<img src={post?.banner.url} alt={post?.title} />}
      />
      <Container as="main" classes="article">
        <section className="article__content">
          <ReactMarkdown children={post?.content} remarkPlugins={[remarkGfm]} />
        </section>
        {!!assets.files.length && (
          <section className="article__assets">
            {assets.files.map(asset => (
              <DownloadCard
                key={asset.fileName}
                downloadPath={asset.url}
                title={asset.fileName}
              />
            ))}
          </section>
        )}
        {!!assets.images?.length && (
          <>
            <Title as="h2" size="2" classes='article__title'>
              Galeria
            </Title>
            <ReactFullscreenSlideshow
              images={assets.images}
              title={"Example Image slideshow"}
            />
          </>
        )}
      </Container>
    </>
  )
}

export default Article
